import React from "react";
import { CopyBlock, dracula } from "react-code-blocks";

const CodeBlock = (props) => {
  const { text = "" } = props;
  return (
    <div className="code-blocks mb-3 overflow-x-auto">
      <CopyBlock
        text={text}
        language={"jsx"}
        showLineNumbers={8}
        theme={dracula}
        wrapLines
        customStyle={{
          textAlign: "start",
          overflowX: "auto",
          wordWrap: "break-word",
        }}
      />
    </div>
  );
};

export default CodeBlock;
