import React from "react";
import blog1Img from "../../../Assets/Images/blog/blog-1.jpg";
import blog2Img from "../../../Assets/Images/blog/blog-2.jpg";
import blog3Img from "../../../Assets/Images/blog/blog-3.jpg";
import htmlLogo from "Assets/Images/language/HTML-thumbnail.png";
import cssLogo from "Assets/Images/language/css-thumbnail.jpg";
import { Link } from "react-router-dom";

const RecentPosts = () => {
  return (
    <>
      <div className="container section-title" data-aos="fade-up">
        <h2>Recent Posts</h2>
        <p>Recent posts form our Blog</p>
      </div>
      <div className="container">
        <div className="row gy-5">
          <div className="col-xl-4 col-md-6">
            <div
              className="post-item position-relative h-100"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="post-img position-relative overflow-hidden h-50">
                <img src={htmlLogo} className="img-fluid " alt="HTML Image" />
                <span className="post-date">July 06</span>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  <Link to={"/blog/detail/HTML"}>
                    The Ultimate Beginner's Guide to Building Your First Website
                  </Link>
                </h3>

                <div className="meta d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-person"></i>{" "}
                    <span className="ps-2">Kishan Detroja</span>
                  </div>
                  <span className="px-3 text-black-50">/</span>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-folder2"></i>{" "}
                    <span className="ps-2">Education</span>
                  </div>
                </div>

                <hr />

                <Link to="/blog/detail/HTML" className="readmore stretched-link">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6">
            <div
              className="post-item position-relative h-100"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="post-img position-relative overflow-hidden h-50">
                <img src={cssLogo} className="img-fluid " alt="Css image" />
                <span className="post-date">July 06</span>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                <Link to={"/blog/detail/CSS"}>
                  CSS for Beginners: A Comprehensive Guide to Styling Your Web
                  Pages
                  </Link>
                </h3>

                <div className="meta d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-person"></i>{" "}
                    <span className="ps-2">Kishan Detroja</span>
                  </div>
                  <span className="px-3 text-black-50">/</span>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-folder2"></i>{" "}
                    <span className="ps-2">Education</span>
                  </div>
                </div>

                <hr />

                <Link to="/blog/detail/CSS" className="readmore stretched-link">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div
            className="col-xl-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="post-item position-relative h-100">
              <div className="post-img position-relative overflow-hidden h-50">
                <img src={blog3Img} className="img-fluid" alt="" />
                <span className="post-date">September 05</span>
              </div>

              <div className="post-content d-flex flex-column">
                <h3 className="post-title">
                  Quia assumenda est et veritati tirana ploder
                </h3>

                <div className="meta d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <i className="bi bi-person"></i>{" "}
                    <span className="ps-2">Lisa Hunter</span>
                  </div>
                  <span className="px-3 text-black-50">/</span>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-folder2"></i>{" "}
                    <span className="ps-2">Economics</span>
                  </div>
                </div>

                <hr />

                <a href="blog-details.html" className="readmore stretched-link">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4 tutorial-home">
          <Link to="/blog" className="btn-get-started">
            View All Blogs <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
};

export default RecentPosts;
