import React from "react";
import { Route, Routes } from "react-router";
import HomePage from "./Components/Home/HomePage";
import MainLayout from "./Components/Layout/MainLayout";
import BlogMain from "./Components/Blog/BlogMain";
import TutorialDetail from "./Components/Tutotials/TutorialDetail";
import TutorialList from "./Components/Tutotials/TutorialList";
import NoMatch from "./Components/Common/NoMatch";
import InterviewMain from "./Components/Inteviews/InterviewMain";
import InterviewList from "Components/Inteviews/InterviewList";

const RouteFile = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/blog" element={<BlogMain />}></Route>
        <Route path="/blog/detail/:id" element={<BlogMain />}></Route>
        <Route path="/tutorial/detail/:id" element={<TutorialDetail />}></Route>
        <Route
          path="/tutorial/detail/:id/:topic"
          element={<TutorialDetail />}
        ></Route>
        <Route path="/tutorial/list" element={<TutorialList />}></Route>
        <Route path="/interview/:id" element={<InterviewMain />}></Route>
        <Route path="/interviews" element={<InterviewList />}></Route>
      </Route>

      <Route path="*" element={<NoMatch />}></Route>
    </Routes>
  );
};

export default RouteFile;
