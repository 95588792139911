import React from "react";

const UserDetail = () => {
  return (
    <div class="meta-top d-flex justify-content-center align-items-center ">
      <ul>
        <li class="d-flex align-items-center">
          <i class="bi bi-person"></i> <a href="#">Kishan Detroja</a>
        </li>
        <li class="d-flex align-items-center">
          <i class="bi bi-clock"></i>{" "}
          <a href="#">
            <time datetime="2020-01-01">July 06, 2024</time>
          </a>
        </li>
        <li class="d-flex align-items-center">
          <i class="bi bi-chat-dots"></i>{" "}
          <a href="#">0 Comments</a>
        </li>
      </ul>
    </div>
  );
};

export default UserDetail;
