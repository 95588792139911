import React from "react";
import blogRecent1Img from "../../Assets/Images/blog/blog-recent-1.jpg";
import blogRecent2Img from "../../Assets/Images/blog/blog-recent-2.jpg";
import blogRecent3Img from "../../Assets/Images/blog/blog-recent-3.jpg";
import blogRecent4Img from "../../Assets/Images/blog/blog-recent-4.jpg";
import blogRecent5Img from "../../Assets/Images/blog/blog-recent-5.jpg";

const BlogSidebar = () => {
  return (
    <div class="widgets-container">
      <div class="search-widget widget-item">
        <h3 class="widget-title">Search</h3>
        <form action="">
          <input type="text" />
          <button type="submit" title="Search">
            <i class="bi bi-search"></i>
          </button>
        </form>
      </div>

      <div class="categories-widget widget-item">
        <h3 class="widget-title">Categories</h3>
        <ul class="mt-3">
          <li>
            <a href="#">
              General <span>(25)</span>
            </a>
          </li>
          <li>
            <a href="#">
              Lifestyle <span>(12)</span>
            </a>
          </li>
          <li>
            <a href="#">
              Travel <span>(5)</span>
            </a>
          </li>
          <li>
            <a href="#">
              Design <span>(22)</span>
            </a>
          </li>
          <li>
            <a href="#">
              Creative <span>(8)</span>
            </a>
          </li>
          <li>
            <a href="#">
              Educaion <span>(14)</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="recent-posts-widget widget-item">
        <h3 class="widget-title">Recent Posts</h3>

        <div class="post-item">
          <img src={blogRecent1Img} alt="" class="flex-shrink-0" />
          <div>
            <h4>
              <a href="blog-details.html">Nihil blanditiis at in nihil autem</a>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>

        <div class="post-item">
          <img src={blogRecent2Img} alt="" class="flex-shrink-0" />
          <div>
            <h4>
              <a href="blog-details.html">Quidem autem et impedit</a>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>

        <div class="post-item">
          <img src={blogRecent3Img} alt="" class="flex-shrink-0" />
          <div>
            <h4>
              <a href="blog-details.html">
                Id quia et et ut maxime similique occaecati ut
              </a>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>

        <div class="post-item">
          <img src={blogRecent4Img} alt="" class="flex-shrink-0" />
          <div>
            <h4>
              <a href="blog-details.html">Laborum corporis quo dara net para</a>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>

        <div class="post-item">
          <img src={blogRecent5Img} alt="" class="flex-shrink-0" />
          <div>
            <h4>
              <a href="blog-details.html">
                Et dolores corrupti quae illo quod dolor
              </a>
            </h4>
            <time datetime="2020-01-01">Jan 1, 2020</time>
          </div>
        </div>
      </div>

      <div class="tags-widget widget-item">
        <h3 class="widget-title">Tags</h3>
        <ul>
          <li>
            <a href="#">App</a>
          </li>
          <li>
            <a href="#">IT</a>
          </li>
          <li>
            <a href="#">Business</a>
          </li>
          <li>
            <a href="#">Mac</a>
          </li>
          <li>
            <a href="#">Design</a>
          </li>
          <li>
            <a href="#">Office</a>
          </li>
          <li>
            <a href="#">Creative</a>
          </li>
          <li>
            <a href="#">Studio</a>
          </li>
          <li>
            <a href="#">Smart</a>
          </li>
          <li>
            <a href="#">Tips</a>
          </li>
          <li>
            <a href="#">Marketing</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BlogSidebar;
