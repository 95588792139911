import React from "react";
import { Link } from "react-router-dom";
import htmlLogo from "Assets/Images/tutorials/htmlLogo.png";
import cssLogo from "Assets/Images/tutorials/cssLogo.png";
import jsLogo from "Assets/Images/tutorials/javascriptLogo.png";
import bootstrapLogo from "Assets/Images/tutorials/bootstrap.png";
import reactjsLogo from "Assets/Images/tutorials/reactjs.png";
import tailwindLogo from "Assets/Images/tutorials/tailwind.png";

const Tutorials = () => {
  return (
    <>
      <div className="container section-title" data-aos="fade-up">
        <h2>Tutorials</h2>
        <p>
          Check Our Tutorials
          <br />
        </p>
      </div>

      <div className="container tutorial-home">
        <div className="row gy-4">
          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="service-item item-cyan position-relative">
              <img src={htmlLogo} alt="" height={135} />
              <h3>HTML</h3>

              <Link to="/tutorial/detail/html" className="read-more stretched-link">
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="service-item item-orange position-relative">
              <img src={cssLogo} alt="" height={135} />
              <h3>CSS</h3>
              
              <Link to="/tutorial/detail/css" className="read-more stretched-link">
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="service-item item-teal position-relative">
              <img src={jsLogo} alt="" height={135} />

              <h3>Javascript</h3>
            
              <Link
                to="/tutorial/detail/javascript"
                className="read-more stretched-link"
              >
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="service-item item-red position-relative">
              <img src={bootstrapLogo} alt="" height={135} />

              <h3>Bootstrap</h3>
              
              <Link
                to="/tutorial/detail/bootstrap"
                className="read-more stretched-link"
              >
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div className="service-item item-indigo position-relative">
              <img
                src={tailwindLogo}
                alt=""
                className="px-5 py-3"
                height={135}
              />

              <h3>Tailwind</h3>
            
              <Link
                to="/tutorial/detail/tailwind"
                className="read-more stretched-link"
              >
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div className="service-item item-pink position-relative">
              <img src={reactjsLogo} alt="" height={135} />
              <h3>React Js</h3>
              
              <Link
                to="/tutorial/detail/react-js"
                className="read-more stretched-link"
              >
                <span>Read More</span> <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <Link to="/tutorial/list" className="btn-get-started">
            View All Tutorials <i className="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Tutorials;
