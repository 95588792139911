import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import ShowPageTitle from "../Common/ShowPageTitle";

const MainLayout = () => {
  const [isMobile, setMobile] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const selectBody = document.body;
      const selectHeader = document.getElementById("header");
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 40
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if(isModalOpen){
      const mobileNavToggleBtn = document.querySelector(".mobile-nav-toggle");
      document.querySelector("body").classList.toggle("mobile-nav-active");
      mobileNavToggleBtn.classList.toggle("bi-list");
      mobileNavToggleBtn.classList.toggle("bi-x");
    }else{
      setModalOpen(true)
    }
  }, [isMobile]);

  return (
    <>
      <ShowPageTitle title={"home"} />
      <Header isMobile={isMobile} setMobile={setMobile} />
      <main className="main">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
