import React, { useEffect } from "react";
import BlogList from "./BlogList";
import BlogSidebar from "./BlogSidebar";
import BlogDetail from "./BlogDetail";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import ShowPageTitle from "../Common/ShowPageTitle";

const BlogMain = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const isDetailsPage = pathname.startsWith("/blog/detail");

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [navigate]);
  return (
    <div className="mt-7">
      <ShowPageTitle title={"Blog"} />
      <div class="page-title">
        {/* <div class="heading">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
                <h1>{isDetailsPage ? "Blog Details" : "Blog"}</h1>
                <p class="mb-0">
                  Odio et unde deleniti. Deserunt numquam exercitationem.
                  Officiis quo odio sint voluptas consequatur ut a odio
                  voluptatem. Sit dolorum debitis veritatis natus dolores. Quasi
                  ratione sint. Sit quaerat ipsum dolorem.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <nav class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <Link className="" to={"/"}>
                  Home
                </Link>
              </li>
              <li>
                <Link className="" to={"/blog"}>
                  Blogs
                </Link>
              </li>
              {!!id && <li class="current">{id}</li>}
            </ol>
          </div>
        </nav>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            {/* <BlogList /> */}
            {isDetailsPage ? <BlogDetail /> : <BlogList />}
          </div>

          <div class="col-lg-4 sidebar">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
