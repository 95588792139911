import React from "react";
import htmlLogo from "../../../Assets/Images/tutorials/htmlLogo.png";
import cssLogo from "../../../Assets/Images/tutorials/cssLogo.png";
import jsLogo from "../../../Assets/Images/tutorials/javascriptLogo.png";
import bootstrapLogo from "../../../Assets/Images/tutorials/bootstrap.png";
import reactjsLogo from "../../../Assets/Images/tutorials/reactjs.png";
import tailwindLogo from "../../../Assets/Images/tutorials/tailwind.png";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const Interviews = () => {
  return (
    <>
      <div className="container section-title" data-aos="fade-up">
        <h2>Be Prepared</h2>
        <p>
          Interview Questions & Answers
          <br />
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper init-swiper">
          <Swiper
            slidesPerView={5}
            rewind={true}
            loop={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                src={htmlLogo}
                className="img-fluid"
                alt=""
                height={50}
                width={100}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={cssLogo} className="img-fluid" alt="" height={100} />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={jsLogo}
                className="img-fluid"
                alt=""
                height={100}
                width={100}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src={bootstrapLogo} className="img-fluid" alt="" height={100} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={tailwindLogo} className="img-fluid" alt="" height={100} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={reactjsLogo} className="img-fluid" alt="" height={100} />
            </SwiperSlide>
          </Swiper>

          <div className="swiper-pagination"></div>
        </div>
      </div>
    </>
  );
};

export default Interviews;
