export const pageTitles = {
  home: "Home",
  blog: "Blog",
};

export const tutorialTopic = {
  html: [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
  css: [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
  bootstrap: [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
  javascript: [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
  "react-js": [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
  "tailwind-js": [
    { title: "Introduction", key: "introduction" },
    { title: "Basics", key: "basic" },
    { title: "Elements", key: "elements" },
    { title: "Attributes", key: "attributes" },
    { title: "Headings", key: "headings" },
  ],
};

export const tutorialData = {
  html: [
    {
      heading: "What is HTML",
      description:
        "HTML is the standard markup language for creating Web pages.",
      code: `<!DOCTYPE html>
<html>
<head>
<title>Page Title</title>
</head>
<body>

<h1>My First Heading</h1>
<p>My first paragraph.</p>

</body>
</html>`,

      key: "introduction",
    },
    { heading: "Basics", description: "", key: "basic" },
    { heading: "Elements", description: "", key: "elements" },
    { heading: "Attributes", description: "", key: "attributes" },
    { heading: "Headings", description: "", key: "headings" },
  ],
  css: [
    {
      heading: "What is CSS",
      description: "Cascading Style Sheet",
      key: "introduction",
    },
    { heading: "Basics", description: "", key: "basic" },
    { heading: "Elements", description: "", key: "elements" },
    { heading: "Attributes", description: "", key: "attributes" },
    { heading: "Headings", description: "", key: "headings" },
  ],
};
