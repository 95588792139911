import { pageTitles } from "../../Modules/data";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ShowPageTitle = ({ title }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const showTitle = pageTitles?.[title] ?? title;
    document.title = showTitle ? `CodeTray | ${showTitle}` : "CodeTray";
  }, [navigate]);

  return <div></div>;
};

export default ShowPageTitle;
