import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import htmlLogo from "Assets/Images/tutorials/htmlLogo.png";
import cssLogo from "Assets/Images/tutorials/cssLogo.png";
import jsLogo from "Assets/Images/tutorials/jsLogo.png";
import bootstrapLogo from "Assets/Images/tutorials/bootstrap.png";
import tailwindLogo from "Assets/Images/tutorials/tailwind.png";
import reactLogo from "Assets/Images/tutorials/reactjs.png";
import { interviewData } from "../../Modules/interviewData";
import { isValidArray } from "../../Modules/util";
import classNames from "classnames";
import { Accordion } from "react-bootstrap";
import CodeBlock from "Components/Common/CodeBlock";

const InterviewMain = () => {
  const { id } = useParams();

  const [accordion, setaccordian] = useState({});
  const [openAccordian, setOpenAccordian] = useState([1]);

  useEffect(() => {
    setaccordian(interviewData[id]);
  }, [id]);

  const TopicImage = {
    html: htmlLogo,
    css: cssLogo,
    javascript: jsLogo,
    bootstrap: bootstrapLogo,
    tailwind: tailwindLogo,
    reactjs: reactLogo,
  };

  return (
    <>
      <div class="page-title">
        <nav class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/tutorial/list"}>Interview</Link>
              </li>
              <li class="current">{id?.toUpperCase()}</li>
            </ol>
          </div>
        </nav>
        <div class="px-1">
          <div class="container px-4 py-2 interview-nav-tab overflow-x-auto d-flex justify-content-center align-items-center gap-1">
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/html"}>HTML</Link>
            </span>
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/css"}>Css</Link>
            </span>
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/javascript"}>Javascript</Link>
            </span>
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/bootstrap"}>Bootstrap</Link>
            </span>
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/tailwind"}>Tailwind</Link>
            </span>
            <span className="border p-1 rounded-4 border-primary fw-bold">
              <Link to={"/interview/reactjs"}>ReactJs</Link>
            </span>
          </div>
        </div>
        <div class="heading">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
                <img src={TopicImage[id] ?? htmlLogo} alt="" height={135} />
                <p class="mb-0">
                  HTML is the standard markup language for Web pages. With HTML
                  you can create your own Website. HTML is easy to learn - You
                  will enjoy it!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="service-details" class="service-details section">
        <div class="container">
          <div class="row gy-0">
            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
              {/* <div class="service-box">
                <h4>{id ? id.toUpperCase() : ""} Tutorial</h4>
                <div class="services-list">
                  {tutorialTopic?.[id]?.map((item) => {
                    return (
                      <Link
                        key={item.key}
                        className={classNames("", {
                          active: item.key == topic,
                        })}
                        to={`/tutorial/detail/${id}/${item.key}`}
                      >
                        <i class="bi bi-arrow-right-circle"></i>
                        <span>{item?.title ?? ""}</span>
                      </Link>
                    );
                  })}
              
                </div>
              </div> */}

              {/* <div class="service-box">
                <h4>Interview Preparation</h4>
                <div class="download-catalog">
                  <Link to="/">
                    <i class="bi bi-filetype-pdf"></i>
                    <span>HTML</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>CSS</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>Javascript</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>ReactJs</span>
                  </Link>
                </div>
              </div> */}

              {/* <div class="help-box d-flex flex-column justify-content-center align-items-center">
                <i class="bi bi-headset help-icon"></i>
                <h4>Have a Question?</h4>
                <p class="d-flex align-items-center mt-2 mb-0">
                  <i class="bi bi-telephone me-2"></i>{" "}
                  <span>+1 5589 55488 55</span>
                </p>
                <p class="d-flex align-items-center mt-1 mb-0">
                  <i class="bi bi-envelope me-2"></i>{" "}
                  <a href="mailto:contact@example.com">contact@example.com</a>
                </p>
              </div> */}
            </div>

            <div class="col-lg-12 " data-aos="fade-up" data-aos-delay="200">
              <div class="accordion" id="accordionPanelsStayOpenExample">
                <Accordion defaultActiveKey={openAccordian} alwaysOpen>
                  {isValidArray(accordion) ? (
                    accordion?.map((item) => {
                      return (
                        <Accordion.Item eventKey={item.id} key={item.id}>
                          <Accordion.Header className="">
                            <h6 className="fw-bold ">
                              Q.{item?.id ?? ""} {item?.question ?? ""}
                            </h6>{" "}
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>{item?.answer ?? ""}</p>
                            {item?.code && (
                              <CodeBlock text={item?.code ?? ""} />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center">
                      <h5>Coming Soon...</h5>
                    </div>
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterviewMain;
