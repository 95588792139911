import React from "react";
import HeroImg from "../../../Assets/Images/hero-img.png";
import LightGallary from "../../Common/LightGallary/LightGallary";
import { Link } from "react-router-dom";

const Section1 = () => {
  return (
    <div className="container">
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">
            We offer modern solutions for growing your knowledge
          </h1>
          <p data-aos="fade-up" data-aos-delay="100">
            Unlock your potential with our extensive library of tutorials. Gain
            new skills and enhance your expertise with step-by-step guidance and
            expert insights tailored for learners of all levels.
          </p>
          <div
            className="d-flex flex-column flex-md-row"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {/* <a href="#about" className="btn-get-started">
              Get Started <i className="bi bi-arrow-right"></i>
            </a> */}
            <Link to="/tutorial/list" className="btn-get-started">
              Get Started
            </Link>
            {/* <a
              href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
              className="glightbox btn-watch-video d-flex align-items-center justify-content-center ms-0 ms-md-4 mt-4 mt-md-0"
            >
              <i className="bi bi-play-circle"></i>
              <span>Watch Video</span>
              
            </a> */}
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
          <img src={HeroImg} className="img-fluid animated" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
