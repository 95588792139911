import React from "react";
import cssLogo from "Assets/Images/language/css-thumbnail.jpg";
import { CopyBlock, dracula } from "react-code-blocks";
import UserDetail from "../Common/UserDetail";
import CodeBlock from "../../Common/CodeBlock";
import ShowPageTitle from "Components/Common/ShowPageTitle";

const CSS = () => {
  return (
    <div>
      <ShowPageTitle title={"CSS for Beginners: A Comprehensive Guide to Styling Your Web Pages"} />
      <div id="blog-details" class="blog-details section">
        <div class="container">
          <article class="article">
            <div class="post-img d-flex justify-content-center ">
              <img src={cssLogo} alt="css thumbnail" class="img-fluid w-50 h-50" />
            </div>

            <h2 class="title">
              CSS for Beginners: A Comprehensive Guide to Styling Your Web Pages
            </h2>

            <UserDetail />

            <div class="content">
              <p>
                Cascading Style Sheets (CSS) is a powerful tool for styling and
                designing web pages, allowing you to control layout, colors,
                fonts, and more. This beginner's guide to CSS covers the basics,
                from understanding selectors and properties to creating
                responsive designs. Perfect for anyone new to web development,
                this guide will help you transform plain HTML into visually
                appealing, well-organized web pages.
              </p>
              <h3>1. Basic Structure of CSS</h3>
              <h5>CSS can be added to HTML in three ways:</h5>
              <ol>
                <li>
                  {" "}
                  <span className="fw-semibold">Inline CSS :</span> Directly
                  within an using the `style` attribute.
                </li>
                <li>
                  {" "}
                  <span className="fw-semibold">Internal CSS :</span> Within a{" "}
                  {`<style>`} tag in the HTML {`<head>`}{" "}
                </li>
                <li>
                  {" "}
                  <span className="fw-semibold">External CSS :</span> In a
                  separate `.css` file linked to the HTML document.
                </li>
              </ol>
              <h6>Example of Inline CSS:</h6>

              <CodeBlock
                text={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Inline CSS Example</title>
</head>
<body>
    <h1 style="color: blue; text-align: center;">Welcome to CSS</h1>
    <p style="font-size: 16px; color: gray;">
    This paragraph is styled with inline CSS.
    </p>
</body>
</html>`}
              />

              <h6>Example of Internal CSS:</h6>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Internal CSS Example</title>
    <style>
        h1 {
        color: blue;
        text-align: center;
        }
                  
        p {
        font-size: 16px;
        color: gray;
        }
    </style>
</head>
<body>
    <h1>Welcome to CSS</h1>
    <p>This paragraph is styled with internal CSS.</p>
</body>
</html>`}
                />
              </div>
              <h6>Example of External CSS:</h6>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>CSS Tutorial</title>
    <link rel="stylesheet" href="./styles.css">
</head>
<body>
    <h1>Welcome to CSS</h1>
    <p>This is a paragraph styled with CSS.</p>
</body>
</html>`}
                />
              </div>
              <p>{`styles.css`} file.</p>
              {/* <blockquote></blockquote> */}
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`h1 {
    color: blue;
    text-align: center;
}
                
p {
    font-size: 16px;
    color: gray;
}`}
                />
              </div>
              <h3>2. Selectors</h3>
              <h6>
                Selectors are used to target HTML elements for styling. Here are
                some common selectors:
              </h6>
              <p>
                {" "}
                <span className="fw-bold">- Element Selector :</span> Targets
                all elements of a specific type.
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`p {
    color: black;
}`}
                />
              </div>
              <p>
                {" "}
                <span className="fw-bold">- Class Selector :</span> Targets
                elements with a specific class attribute. Use a dot (.) before
                the class name.
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`.highlight {
    background-color: yellow;
}`}
                />
              </div>
              <p>
                {" "}
                <span className="fw-bold">- ID Selector :</span> Targets a
                single element with a specific ID attribute. Use a hash (#)
                before the ID name.
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`#main-title {
    font-size: 24px;
}`}
                />
              </div>
              <h3>3. Properties and Values</h3>
              <h6>
                CSS properties control various aspects of the element's
                appearance. Each property has a value.
              </h6>
              <p>
                {" "}
                <span className="fw-bold">- Example :</span>
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`p {
    color: red;
    font-size: 18px;
    margin: 10px;
    padding: 5px;
}`}
                />
              </div>
              <h3>4. Box Model</h3>
              <h6>
                The CSS box model consists of margins, borders, padding, and the
                content itself.
              </h6>
              <p>
                {" "}
                <span className="fw-bold">- Example :</span>
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`div {
    width: 200px;
    padding: 10px;
    border: 1px solid black;
    margin: 20px;
}`}
                />
              </div>
              <h3>5. Layouts with Flexbox</h3>
              <h6>
                Flexbox is a powerful layout module for aligning items in a
                container.
              </h6>
              <p>
                {" "}
                <span className="fw-bold">- Example :</span>
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`.container {
    display: flex;
    justify-content: space-between;
}
                
.item {
    background-color: lightblue;
    padding: 20px;
    margin: 10px;
}`}
                />
              </div>
              <p>
                {" "}
                <span className="fw-normal">HTML:</span>
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`<div class="container">
    <div class="item">Item 1</div>
    <div class="item">Item 2</div>
    <div class="item">Item 3</div>
</div>`}
                />
              </div>
              <h3>6. Responsive Design with Media Queries</h3>
              <h6>
                Media queries allow you to apply styles based on the device's
                characteristics, such as screen width.
              </h6>
              <p>
                {" "}
                <span className="fw-bold">- Example :</span>
              </p>
              <div className="code-blocks mb-3">
                <CodeBlock
                  text={`@media (max-width: 600px) {
  body {
    background-color: lightgray;
    }
                
  .container {
    flex-direction: column;
    }
}`}
                />
              </div>

              <h3>Conclusion</h3>
              <p>
                {" "}
                This tutorial covered the basics of CSS, including how to apply
                styles, use selectors, understand the box model, create layouts
                with Flexbox, and make your designs responsive. Practice these
                concepts by experimenting with your own styles and building
                various layouts. With time and practice, you'll become
                proficient in creating visually appealing and well-structured
                web pages.
              </p>
            </div>

            <div class="meta-top">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="bi bi-folder"></i>

                  <a href="#">Eduction</a>
                </li>
                <li class="d-flex align-items-center gap-1">
                  <i class="bi bi-tags"></i> <a href="#">Learn</a>
                  {", "}
                  <a href="#">Tech</a>
                  {", "}
                  <a href="#">Programming</a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>

      {/* <section id="blog-comments" class="blog-comments section">
        <div class="container">
          <h4 class="comments-count">8 Comments</h4>

          <div id="comment-1" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-1.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Georgia Reader</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Et rerum totam nisi. Molestiae vel quam dolorum vel voluptatem
                  et et. Est ad aut sapiente quis molestiae est qui cum soluta.
                  Vero aut rerum vel. Rerum quos laboriosam placeat ex qui. Sint
                  qui facilis et.
                </p>
              </div>
            </div>
          </div>

          <div id="comment-2" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-2.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Aron Alvarado</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Ipsam tempora sequi voluptatem quis sapiente non. Autem itaque
                  eveniet saepe. Officiis illo ut beatae.
                </p>
              </div>
            </div>

            <div id="comment-reply-1" class="comment comment-reply">
              <div class="d-flex">
                <div class="comment-img">
                  <img src="assets/img/blog/comments-3.jpg" alt="" />
                </div>
                <div>
                  <h5>
                    <a href="">Lynda Small</a>{" "}
                    <a href="#" class="reply">
                      <i class="bi bi-reply-fill"></i> Reply
                    </a>
                  </h5>
                  <time datetime="2020-01-01">01 Jan,2022</time>
                  <p>
                    Enim ipsa eum fugiat fuga repellat. Commodi quo quo dicta.
                    Est ullam aspernatur ut vitae quia mollitia id non. Qui ad
                    quas nostrum rerum sed necessitatibus aut est. Eum officiis
                    sed repellat maxime vero nisi natus. Amet nesciunt nesciunt
                    qui illum omnis est et dolor recusandae. Recusandae sit ad
                    aut impedit et. Ipsa labore dolor impedit et natus in porro
                    aut. Magnam qui cum. Illo similique occaecati nihil modi
                    eligendi. Pariatur distinctio labore omnis incidunt et
                    illum. Expedita et dignissimos distinctio laborum minima
                    fugiat. Libero corporis qui. Nam illo odio beatae enim
                    ducimus. Harum reiciendis error dolorum non autem quisquam
                    vero rerum neque.
                  </p>
                </div>
              </div>

              <div id="comment-reply-2" class="comment comment-reply">
                <div class="d-flex">
                  <div class="comment-img">
                    <img src="assets/img/blog/comments-4.jpg" alt="" />
                  </div>
                  <div>
                    <h5>
                      <a href="">Sianna Ramsay</a>{" "}
                      <a href="#" class="reply">
                        <i class="bi bi-reply-fill"></i> Reply
                      </a>
                    </h5>
                    <time datetime="2020-01-01">01 Jan,2022</time>
                    <p>
                      Et dignissimos impedit nulla et quo distinctio ex nemo.
                      Omnis quia dolores cupiditate et. Ut unde qui eligendi
                      sapiente omnis ullam. Placeat porro est commodi est
                      officiis voluptas repellat quisquam possimus. Perferendis
                      id consectetur necessitatibus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="comment-3" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-5.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Nolan Davidson</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Distinctio nesciunt rerum reprehenderit sed. Iste omnis eius
                  repellendus quia nihil ut accusantium tempore. Nesciunt
                  expedita id dolor exercitationem aspernatur aut quam ut.
                  Voluptatem est accusamus iste at. Non aut et et esse qui sit
                  modi neque. Exercitationem et eos aspernatur. Ea est
                  consequuntur officia beatae ea aut eos soluta. Non qui dolorum
                  voluptatibus et optio veniam. Quam officia sit nostrum
                  dolorem.
                </p>
              </div>
            </div>
          </div>

          <div id="comment-4" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-6.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Kay Duggan</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Dolorem atque aut. Omnis doloremque blanditiis quia eum porro
                  quis ut velit tempore. Cumque sed quia ut maxime. Est ad aut
                  cum. Ut exercitationem non in fugiat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section id="comment-form" class="comment-form section">
        <div class="container">
          <form action="">
            <h4>Post Comment</h4>
            <p>
              Your email address will not be published. Required fields are
              marked *{" "}
            </p>
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  name="name"
                  type="text"
                  class="form-control"
                  placeholder="Your Name*"
                />
              </div>
              <div class="col-md-6 form-group">
                <input
                  name="email"
                  type="text"
                  class="form-control"
                  placeholder="Your Email*"
                />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <input
                  name="website"
                  type="text"
                  class="form-control"
                  placeholder="Your Website"
                />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <textarea
                  name="comment"
                  class="form-control"
                  placeholder="Your Comment*"
                ></textarea>
              </div>
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-primary">
                Post Comment
              </button>
            </div>
          </form>
        </div>
      </section> */}
    </div>
  );
};

export default CSS;
