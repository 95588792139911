import React from "react";
import { Link } from "react-router-dom";
import blog1Img from "Assets/Images/blog/blog-1.jpg";
import blog2Img from "Assets/Images/blog/blog-2.jpg";
import blog3Img from "Assets/Images/blog/blog-3.jpg";
import blog4Img from "Assets/Images/blog/blog-4.jpg";
import htmlLogo from "Assets/Images/language/HTML-thumbnail.png";
import cssLogo from "Assets/Images/language/css-thumbnail.jpg";
import UserDetail from "./Common/UserDetail";

const BlogList = () => {
  return (
    <>
      <section id="blog-posts" class="blog-posts section">
        <div class="container">
          <div class="row gy-4">
            <div class="col-12">
              <article>
                <div class="post-img d-flex justify-content-center align-items-center">
                  <img src={htmlLogo} alt="" class="img-fluid w-50 h-50" />
                </div>

                <h2 class="title">
                  <Link className="" to={"/blog/detail/HTML"}>
                    Mastering HTML: The Ultimate Beginner's Guide to Building
                    Your First Website
                  </Link>
                </h2>

                <UserDetail />

                <div class="content">
                  <p>
                    HTML (Hypertext Markup Language) is the foundational
                    language for creating web pages. It structures content on
                    the web, using elements like headings, paragraphs, links,
                    and images. Learning HTML is the first step for anyone
                    looking to build websites, enabling them to design and
                    organize web content effectively. This guide provides a
                    comprehensive introduction to HTML, making it easy for
                    beginners to start their journey in web development.
                  </p>

                  <div class="read-more">
                    {/* <a href="blog-details.html">Read More</a> */}
                    <Link className="" to={"/blog/detail/HTML"}>
                      Read More
                    </Link>
                  </div>
                </div>
              </article>
            </div>

            <div class="col-12">
              <article>
                <div class="post-img d-flex justify-content-center align-items-center">
                  <img src={cssLogo} alt="" class="img-fluid w-50 h-50" />
                </div>

                <h2 class="title">
                  <Link className="" to={"/blog/detail/CSS"}>
                    CSS for Beginners: A Comprehensive Guide to Styling Your Web
                    Pages
                  </Link>
                </h2>

                <UserDetail />

                <div class="content">
                  <p>
                    Cascading Style Sheets (CSS) is a powerful tool for styling
                    and designing web pages, allowing you to control layout,
                    colors, fonts, and more. This beginner's guide to CSS covers
                    the basics, from understanding selectors and properties to
                    creating responsive designs. Perfect for anyone new to web
                    development, this guide will help you transform plain HTML
                    into visually appealing, well-organized web pages.
                  </p>
                  <div class="read-more">
                    {/* <a href="blog-details.html">Read More</a> */}
                    <Link className="" to={"/blog/detail/CSS"}>
                      Read More
                    </Link>
                  </div>
                </div>
              </article>
            </div>

            <div class="col-12">
              <article>
                <div class="post-img">
                  <img src={blog3Img} alt="" class="img-fluid" />
                </div>

                <h2 class="title">
                  <Link className="" to={"/blog/detail/3"}>
                    Possimus soluta ut id suscipit ea ut. In quo quia et soluta
                    libero sit sint.
                  </Link>
                </h2>

                <div class="meta-top">
                  <ul>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-person"></i>{" "}
                      <a href="blog-details.html">John Doe</a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-clock"></i>{" "}
                      <a href="blog-details.html">
                        <time datetime="2022-01-01">Jan 1, 2022</time>
                      </a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-chat-dots"></i>{" "}
                      <a href="blog-details.html">12 Comments</a>
                    </li>
                  </ul>
                </div>

                <div class="content">
                  <p>
                    Aut iste neque ut illum qui perspiciatis similique
                    recusandae non. Fugit autem dolorem labore omnis et. Eum
                    temporibus fugiat voluptate enim tenetur sunt omnis.
                    Doloremque est saepe laborum aut. Ipsa cupiditate ex harum
                    at recusandae nesciunt. Ut dolores velit.
                  </p>
                  <div class="read-more">
                    <a href="blog-details.html">Read More</a>
                  </div>
                </div>
              </article>
            </div>

            <div class="col-12">
              <article>
                <div class="post-img">
                  <img src={blog4Img} alt="" class="img-fluid" />
                </div>

                <h2 class="title">
                  <Link className="" to={"/blog/detail/1"}>
                    Non rem rerum nam cum quo minus. Dolor distinctio deleniti
                    explicabo eius exercitationem.
                  </Link>
                </h2>

                <div class="meta-top">
                  <ul>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-person"></i>{" "}
                      <a href="blog-details.html">John Doe</a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-clock"></i>{" "}
                      <a href="blog-details.html">
                        <time datetime="2022-01-01">Jan 1, 2022</time>
                      </a>
                    </li>
                    <li class="d-flex align-items-center">
                      <i class="bi bi-chat-dots"></i>{" "}
                      <a href="blog-details.html">12 Comments</a>
                    </li>
                  </ul>
                </div>

                <div class="content">
                  <p>
                    Aspernatur rerum perferendis et sint. Voluptates cupiditate
                    voluptas atque quae. Rem veritatis rerum enim et autem.
                    Saepe atque cum eligendi eaque iste omnis a qui. Quia sed
                    sunt. Ea asperiores expedita et et delectus voluptates
                    rerum. Id saepe ut itaque quod qui voluptas nobis porro
                    rerum. Quam quia nesciunt qui aut est non omnis. Inventore
                    occaecati et quaerat magni itaque nam voluptas. Voluptatem
                    ducimus sint id earum ut nesciunt sed corrupti nemo.
                  </p>
                  <div class="read-more">
                    <a href="blog-details.html">Read More</a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section id="blog-pagination" class="blog-pagination section">
        <div class="container">
          <div class="d-flex justify-content-center">
            <ul>
              <li>
                <a href="#">
                  <i class="bi bi-chevron-left"></i>
                </a>
              </li>
              <li>
                <a href="#">1</a>
              </li>
              <li>
                <a href="#" class="active">
                  2
                </a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">4</a>
              </li>
              <li>...</li>
              <li>
                <a href="#">10</a>
              </li>
              <li>
                <a href="#">
                  <i class="bi bi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogList;
