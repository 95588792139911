import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import htmlLogo from "../../Assets/Images/tutorials/htmlLogo.png";
import cssLogo from "../../Assets/Images/tutorials/cssLogo.png";
import jsLogo from "../../Assets/Images/tutorials/javascriptLogo.png";
import bootstrapLogo from "../../Assets/Images/tutorials/bootstrap.png";
import reactjsLogo from "../../Assets/Images/tutorials/reactjs.png";
import tailwindLogo from "../../Assets/Images/tutorials/tailwind.png";

const InterviewList = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [navigate]);
  return (
    <>
      <div class="page-title">
        <nav class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="current">Interviews</li>
            </ol>
          </div>
        </nav>
        <div class="heading">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
                <h1>Interview Resources</h1>
                <p class="mb-0">
                  Essential tools and materials to help you prepare and excel in
                  job interviews, including practice questions, tips, and mock
                  interviews.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="services" class="services section">
        <div class="container tutorial-home">
          <div class="row gy-4">
            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="service-item item-cyan position-relative">
                <img src={htmlLogo} alt="" height={135} />
                <h3>HTML</h3>

                <Link to="/interview/html" class="read-more stretched-link">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="service-item item-orange position-relative">
                <img src={cssLogo} alt="" height={135} />
                <h3>CSS</h3>

                <Link to="/interview/css" class="read-more stretched-link">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="service-item item-teal position-relative">
                <img src={jsLogo} alt="" height={135} />

                <h3>Javascript</h3>

                <Link
                  to="/interview/javascript"
                  class="read-more stretched-link"
                >
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div class="service-item item-red position-relative">
                <img src={bootstrapLogo} alt="" height={135} />

                <h3>Bootstrap</h3>

                <Link
                  to="/interview/bootstrap"
                  class="read-more stretched-link"
                >
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div class="service-item item-indigo position-relative">
                <img
                  src={tailwindLogo}
                  alt=""
                  className="px-5 py-3"
                  height={135}
                />

                <h3>Tailwind</h3>

                <Link to="/interview/tailwind" class="read-more stretched-link">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div class="service-item item-pink position-relative">
                <img src={reactjsLogo} alt="" height={135} />
                <h3>React Js</h3>

                <Link to="/interview/react-js" class="read-more stretched-link">
                  <span>Read More</span> <i class="bi bi-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterviewList;
