import React, { useEffect, useState } from "react";

import Logo from "Assets/Images/ctlogo.png";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = ({ isMobile = false, setMobile = () => {} }) => {
  const [currentItem, setCurrentItem] = useState("");
  const toggleMobile = () => setMobile(!isMobile);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setMobile(false);
  }, [navigate]);

  return (
    <header
      id="header"
      className={classNames("header d-flex align-items-center", {
        "fixed-top": pathname === "/",
        "sticky-top": pathname !== "/",
      })}
    >
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <Link to="/" className="logo d-flex align-items-center me-auto">
          <img src={Logo} alt="" />
          <h1 className="sitename">CodeTray</h1>
        </Link>

        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <Link
                className={classNames("", {
                  active: pathname === "/",
                })}
                to={"/"}
              >
                Home
              </Link>
            </li>

            <li>
              <Link
                className={classNames("", {
                  active: pathname.startsWith("/tutorial"),
                })}
                to={"/tutorial/list"}
              >
                Tutorials
              </Link>
            </li>
            <li>
              <Link
                className={classNames("", {
                  active: pathname.startsWith("/interview"),
                })}
                to={"/interviews"}
              >
                Interview Preparation
              </Link>
            </li>
            {/* <li>
              <a href="#team">Team</a>
            </li> */}
            <li>
              <Link
                className={classNames("", {
                  active: pathname.startsWith("/blog"),
                })}
                to={"/blog"}
              >
                Blog
              </Link>
            </li>
            {/* <li className="dropdown">
              <a href="#">
                <span>Dropdown</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Dropdown 1</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Deep Dropdown</span>{" "}
                    <i className="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Dropdown 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Dropdown 2</a>
                </li>
                <li>
                  <a href="#">Dropdown 3</a>
                </li>
                <li>
                  <a href="#">Dropdown 4</a>
                </li>
              </ul>
            </li>
            <li className="listing-dropdown">
              <a href="#">
                <span>Listing Dropdown</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Column 1 link 1</a>
                  <a href="#">Column 1 link 2</a>
                  <a href="#">Column 1 link 3</a>
                </li>
                <li>
                  <a href="#">Column 2 link 1</a>
                  <a href="#">Column 2 link 2</a>
                  <a href="#">Column 3 link 3</a>
                </li>
                <li>
                  <a href="#">Column 3 link 1</a>
                  <a href="#">Column 3 link 2</a>
                  <a href="#">Column 3 link 3</a>
                </li>
                <li>
                  <a href="#">Column 4 link 1</a>
                  <a href="#">Column 4 link 2</a>
                  <a href="#">Column 4 link 3</a>
                </li>
                <li>
                  <a href="#">Column 5 link 1</a>
                  <a href="#">Column 5 link 2</a>
                  <a href="#">Column 5 link 3</a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a href="#contact">Contact</a>
            </li> */}
          </ul>
          <i
            className={classNames("mobile-nav-toggle d-xl-none bi bi-list")}
            onClick={toggleMobile}
          ></i>
        </nav>

        <Link to="/tutorial/list" className="btn-getstarted ">
          Get Started
        </Link>
      </div>
    </header>
  );
};

export default Header;
