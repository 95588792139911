import React, { useCallback, useEffect, useRef } from "react";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const LightGallary = () => {
  const lightGallery = useRef(null);

  useEffect(() => {
    if (lightGallery.current) {
      lightGallery.current.init();
    }
  }, []);

  return (
    // <div className="App">
    <LightGallery
      ref={lightGallery}
      // onInit={onInit}
      speed={500}
      plugins={[lgThumbnail, lgZoom, lgVideo]}
    >
      {/* <a src="https://www.youtube.com/watch?v=LXb3EKWsInQ">
          <img alt="img1" src="img/thumb1.jpg" />
        </a> */}
      <a src="https://www.youtube.com/watch?v=oUFJJNQGwhk">
        <img
          width="300"
          height="100"
          className="img-responsive"
          src="https://img.youtube.com/vi/EIUJfXk3_3w/maxresdefault.jpg"
        />
      </a>
    </LightGallery>
    // </div>
  );
};

export default LightGallary;
