export const interviewData = {
  html: [
    {
      id: 1,
      question: "What is HTML",
      answer:
        "HTML stands for HyperText Markup Language and it is used to create webpages. It uses HTML tags and attributes to describe the structure and formatting of a web page.",
    },
    {
      id: 2,
      question: "What are HTML tags?",
      answer:
        "HTML tags are the building blocks of HTML. They define the structure and content of web pages. Tags are enclosed in angle brackets, like `<tagname>`, and usually come in pairs, with an opening tag and a closing tag, like `<p>` and `</p>`.",
    },
    {
      id: 3,
      question: "What are attributes in HTML?",
      answer:
        "Attributes provide additional information about HTML elements. They are always included in the opening tag and usually come in name/value pairs like name='value'. For example, in `<a href='https://example.com'>Link</a>`, `href` is an attribute of the `<a>` tag.",
    },

    {
      id: 4,
      question: "What are void elements in HTML?",
      answer:
        "HTML elements which do not have closing tags or do not need to be closed are Void elements. For Example <br />, <img />, <hr />, etc.",
    },

    {
      id: 5,
      question: "What are HTML Entities?",
      answer:
        "In HTML some characters are reserved like ‘<’, ‘>’, ‘/’, etc. To use these characters in our webpage we need to use the character entities called HTML Entities.",
    },
    {
      id: 6,
      question: "What is the ‘class’ attribute in HTML?",
      answer:
        "The class attribute is used to specify the class name for an HTML element. Multiple elements in HTML can have the same class value. Also, it is mainly used to associate the styles written in the stylesheet with the HTML elements.",
    },
    {
      id: 7,
      question: "What is the difference between <div> and <span>?",
      answer:
        " `<div>` is a block-level element used to group larger sections of HTML content, whereas `<span>` is an inline element used to group small pieces of text or other inline elements. `<div>` elements typically start on a new line, while `<span>` elements do not.",
    },

    {
      id: 8,
      question: "What is the purpose of the <!DOCTYPE html> declaration?",
      answer:
        " The `<!DOCTYPE html>` declaration is used to specify the HTML version and the type of document. It helps the web browser understand how to render the page correctly. For HTML5, it is written as `<!DOCTYPE html>`.",
    },
    {
      id: 9,
      question: "How do you create a hyperlink in HTML?",
      answer:
        "You create a hyperlink using the `<a>` tag with the href attribute. For example:",
      code: `<a href="https://example.com">Visit Example</a>`,
    },
    {
      id: 10,
      question: "How can you insert an image in HTML?",
      answer:
        " You can insert an image using the `<img>` tag with the src attribute to specify the path to the image, and alt attribute for alternative text. For example:",
      code: `<img src="image.jpg" alt="Description of image">`,
    },
    {
      id: 11,
      question: "What is the difference between <ul> and <ol> tags?",
      answer:
        "The `<ul>` tag is used to create an unordered list (bulleted list), while the `<ol>` tag is used to create an ordered list (numbered list).",
    },
    {
      id: 12,
      question: "What is a semantic HTML?",
      answer:
        "Semantic HTML refers to the use of HTML tags that convey the meaning or role of the content within the tags. Examples include `<header>`, `<footer>`, `<article>`, and `<section>`. Semantic tags improve accessibility and SEO.",
    },
    {
      id: 13,
      question: "How do you create a form in HTML?",
      answer:
        " You create a form using the `<form>` tag, and you can include various form elements such as `<input>`, `<textarea>`, `<button>`, `<select>`, etc. Here is a simple example:",
      code: `<form action="/submit" method="post">
    <label for="name">Name:</label>
    <input type="text" id="name" name="name">
    <input type="submit" value="Submit">
</form>`,
    },
  ],
  css: [
    {
      id: 1,
      question: "What is CSS?",
      answer:
        "CSS (Cascading Style Sheets) is a stylesheet language used for describing the presentation of a document written in HTML or XML. It controls the layout of multiple web pages all at once and allows you to style HTML elements with properties like color, font, size, and spacing.",
    },
  ],
};
