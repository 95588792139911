import React, { useEffect } from "react";
import htmlLogo from "../../Assets/Images/tutorials/htmlLogo.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { tutorialData, tutorialTopic } from "../../Modules/data";
import classNames from "classnames";
import ShowPageTitle from "Components/Common/ShowPageTitle";

const TutorialDetail = () => {
  const { id, topic = "introduction" } = useParams();

  const navigate = useNavigate();
  const finalData = tutorialData?.[id]?.find((item) => item?.key == topic);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
    <ShowPageTitle title={`${id} Tutorial`} />
      <div class="page-title">
        {/* <div class="heading">
          <div class="container">
            <div class="row d-flex justify-content-center text-center">
              <div class="col-lg-8">
         
                <img src={htmlLogo} alt="" height={135} />
                <p class="mb-0">
                  HTML is the standard markup language for Web pages. With HTML
                  you can create your own Website. HTML is easy to learn - You
                  will enjoy it!
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <nav class="breadcrumbs">
          <div class="container">
            <ol>
              <li>
                <Link to={"/"}>Home</Link>
              </li>
              <li>
                <Link to={"/tutorial/list"}>Tutorials</Link>
              </li>
              <li class="current">{id?.toUpperCase()}</li>
            </ol>
          </div>
        </nav>
      </div>

      <section id="service-details" class="service-details section">
        <div class="container">
          <div class="row gy-5">
            <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div class="service-box">
                <h4>{id ? id.toUpperCase() : ""} Tutorial</h4>
                <div class="services-list">
                  {tutorialTopic?.[id]?.map((item) => {
                    return (
                      <Link
                        key={item.key}
                        className={classNames("", {
                          active: item.key == topic,
                        })}
                        to={`/tutorial/detail/${id}/${item.key}`}
                      >
                        <i class="bi bi-arrow-right-circle"></i>
                        <span>{item?.title ?? ""}</span>
                      </Link>
                    );
                  })}
                  {/* <a href="#" class="active">
                    <i class="bi bi-arrow-right-circle"></i>
                    <span>Introduction</span>
                  </a>
                  <a href="#">
                    <i class="bi bi-arrow-right-circle"></i>
                    <span>Web Design</span>
                  </a>
                  <a href="#">
                    <i class="bi bi-arrow-right-circle"></i>
                    <span>Product Management</span>
                  </a>
                  <a href="#">
                    <i class="bi bi-arrow-right-circle"></i>
                    <span>Graphic Design</span>
                  </a>
                  <a href="#">
                    <i class="bi bi-arrow-right-circle"></i>
                    <span>Marketing</span>
                  </a> */}
                </div>
              </div>

              <div class="service-box">
                <h4>Interview Preparation</h4>
                <div class="download-catalog">
                  <Link to="/interview/html">
                    <i class="bi bi-filetype-pdf"></i>
                    <span>HTML</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>CSS</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>Javascript</span>
                  </Link>
                  <Link to="/">
                    <i class="bi bi-file-earmark-word"></i>
                    <span>ReactJs</span>
                  </Link>
                </div>
              </div>

              {/* <div class="help-box d-flex flex-column justify-content-center align-items-center">
                <i class="bi bi-headset help-icon"></i>
                <h4>Have a Question?</h4>
                <p class="d-flex align-items-center mt-2 mb-0">
                  <i class="bi bi-telephone me-2"></i>{" "}
                  <span>+1 5589 55488 55</span>
                </p>
                <p class="d-flex align-items-center mt-1 mb-0">
                  <i class="bi bi-envelope me-2"></i>{" "}
                  <a href="mailto:contact@example.com">contact@example.com</a>
                </p>
              </div> */}
            </div>

            <div
              class="col-lg-8 ps-lg-5"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src="assets/img/services.jpg"
                alt=""
                class="img-fluid services-img"
              />
              <h3>{finalData?.heading ?? ""}</h3>
              <p>{finalData?.description ?? ""}</p>
              {finalData?.code && (
                <pre className="language-html code-block">
                  <code>{finalData?.code ?? ""}</code>
                </pre>
              )}
              <ul>
                <li>
                  <i class="bi bi-check-circle"></i>{" "}
                  <span>Aut eum totam accusantium voluptatem.</span>
                </li>
                <li>
                  <i class="bi bi-check-circle"></i>{" "}
                  <span>
                    Assumenda et porro nisi nihil nesciunt voluptatibus.
                  </span>
                </li>
                <li>
                  <i class="bi bi-check-circle"></i>{" "}
                  <span>Ullamco laboris nisi ut aliquip ex ea</span>
                </li>
              </ul>
              <p>
                Est reprehenderit voluptatem necessitatibus asperiores neque sed
                ea illo. Deleniti quam sequi optio iste veniam repellat odit.
                Aut pariatur itaque nesciunt fuga.
              </p>
              <p>
                Sunt rem odit accusantium omnis perspiciatis officia. Laboriosam
                aut consequuntur recusandae mollitia doloremque est architecto
                cupiditate ullam. Quia est ut occaecati fuga. Distinctio ex
                repellendus eveniet velit sint quia sapiente cumque. Et ipsa
                perferendis ut nihil. Laboriosam vel voluptates tenetur nostrum.
                Eaque iusto cupiditate et totam et quia dolorum in. Sunt
                molestiae ipsum at consequatur vero. Architecto ut pariatur
                autem ad non cumque nesciunt qui maxime. Sunt eum quia impedit
                dolore alias explicabo ea.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TutorialDetail;
