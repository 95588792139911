import React, { useEffect } from "react";
import Section1 from "./Pages/Section1";
import Tutorials from "./Pages/Tutorials";
import RecentPosts from "./Pages/RecentPosts";
import { useNavigate } from "react-router";
import Interviews from "./Pages/Interviews";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [navigate]);
  return (
    <>
      <section id="hero" className="hero section">
        <Section1 />
      </section>
      <section id="services" className="services section">
        <Tutorials />
      </section>
      <section id="recent-posts" className="recent-posts section">
        <RecentPosts />
      </section>
      <section id="clients" className="clients section">
        <Interviews />
      </section>
    </>
  );
};

export default HomePage;
