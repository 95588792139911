import React from "react";
import htmlLogo from "Assets/Images/tutorials/htmlLogo.png";
import { CopyBlock, dracula } from "react-code-blocks";
import { useParams } from "react-router";
import HTML from "./Language/HTML";
import CSS from "./Language/CSS";

const BlogDetail = () => {

  const {id} = useParams()
  if(id == "HTML"){
    return <HTML />
  }
  if(id == "CSS"){
    return <CSS />
  }
  return (
    <div>
      <div id="blog-details" class="blog-details section">
        <div class="container">
          <article class="article">
            <div class="post-img d-flex justify-content-center ">
              <img src={htmlLogo} alt="" class="img-fluid w-25 h-25" />
            </div>

            <h2 class="title">What is HTML?</h2>

            <div class="meta-top">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="bi bi-person"></i>{" "}
                  <a href="blog-details.html">John Doe</a>
                </li>
                <li class="d-flex align-items-center">
                  <i class="bi bi-clock"></i>{" "}
                  <a href="blog-details.html">
                    <time datetime="2020-01-01">Jan 1, 2022</time>
                  </a>
                </li>
                <li class="d-flex align-items-center">
                  <i class="bi bi-chat-dots"></i>{" "}
                  <a href="blog-details.html">12 Comments</a>
                </li>
              </ul>
            </div>

            <div class="content">
              <p>
                HTML (HyperText Markup Language) is the standard language used
                to create web pages. It describes the structure of a webpage
                using a series of elements and tags.
              </p>

              <h2>Basic Structure of an HTML Document</h2>

              <p>
                Every HTML document starts with a {"<!DOCTYPE html>"}{" "}
                declaration, followed by an {"<html>"} element which contains
                the {"<head>"} and {"<body>"} sections.
              </p>
              {/* <blockquote></blockquote> */}
              <div className="code-blocks mb-3">
                <CopyBlock
                  text={`<html>
  <head>
      <title>My First Webpage</title>
  </head>
  <body>
    <h1>Hello, World!</h1>
    <p>This is my first webpage.</p>
  </body>
</html>`}
                  language={"jsx"}
                  showLineNumbers={8}
                  theme={dracula}
                  wrapLines
                  customStyle={{ textAlign: "start" }}
                />
              </div>

              <h3>Key HTML Elements</h3>
              <ol>
                <li>
                  {" "}
                  <span className="fw-semibold">
                    {"<!DOCTYPE html>"} :
                  </span>{" "}
                  Declares the document type and version of HTML.
                </li>
                <li>
                  {" "}
                  <span className="fw-semibold">{"<html>"} :</span> The root
                  element of an HTML page.
                </li>
                <li>
                  <span className="fw-semibold">{"<head>"} :</span> Contains
                  meta-information about the HTML document (like the title,
                  styles, scripts, etc.).
                </li>
                <li>
                  <span className="fw-semibold">{"<title>"} :</span> Sets the
                  title of the webpage (displayed on the browser tab).
                </li>
                <li>
                  <span className="fw-semibold">{"<body>"} :</span> Contains the
                  content of the HTML document, which is displayed in the web
                  browser.
                </li>
              </ol>

              <h3>Common HTML Tags</h3>
              <ul>
                <li>
                  <span className="fw-semibold">Headings :</span> {`<h1>`} to{" "}
                  {`<h6>`} for headings, where {`<h1>`} is the highest level and{" "}
                  {`<h6>`} is the lowest.
                </li>
                <li>
                  {" "}
                  <span className="fw-semibold">Paragraph :</span> {`<p>`} for
                  paragraphs.
                </li>
                <li>
                  <span className="fw-semibold">Links :</span>{" "}
                  {`<a href="URL">`} for hyperlinks.
                </li>
                <li>
                  <span className="fw-semibold">Images :</span>{" "}
                  {`<img src="URL" alt="description">`} for images.
                </li>
                <li>
                  <span className="fw-semibold">Lists :</span> {"<ul>"} for
                  unordered lists, {"<ol>"} for ordered lists, and {"<li>"} for
                  list items.
                </li>
                <li>
                  <span className="fw-semibold">Forms :</span>{" "}
                  {"<form>, <input>, <textarea>, <button>, <select>,"} and{" "}
                  {"<option>"} for form elements.
                </li>
                <li>
                  <span className="fw-semibold">Tables :</span>{" "}
                  {"<table>, <tr>"} for table rows, {"<th>"} for table headers,
                  and {"<td>"} for table data.
                </li>
              </ul>
              <h3>Example of a Simple Webpage</h3>

              <div className="code-blocks mb-3">
                <CopyBlock
                  text={`<!DOCTYPE html>
<html>
  <head>
    <title>My Simple Webpage</title>
  </head>
  <body>
    <h1>Welcome to My Webpage</h1>
    <p>This is a paragraph of text.</p>
                  
    <h2>My Hobbies</h2>
    <ul>
      <li>Reading</li>
      <li>Traveling</li>
      <li>Coding</li>
    </ul>
                  
    <h2>Contact Me</h2>
    <p>Email: <a href="mailto:example@example.com">example@example.com</a></p>
                  
    <h2>Gallery</h2>
    <img src="https://via.placeholder.com/150" alt="Placeholder Image">
                  
    <h2>Feedback</h2>
    <form action="/submit" method="post">
      <label for="name">Name:</label>
      <input type="text" id="name" name="name">
      <br>
      <label for="message">Message:</label>
      <textarea id="message" name="message"></textarea>
      <br>
      <button type="submit">Submit</button>
    </form>
  </body>
</html>
                  `}
                  language={"jsx"}
                  showLineNumbers={8}
                  theme={dracula}
                  wrapLines
                  customStyle={{ textAlign: "start" }}
                />
              </div>

              <h3>Tips for Writing HTML</h3>

              <ol>
                <li>
                  <span className="fw-semibold">Use Semantic Tags :</span> Use
                  HTML5 semantic tags like{" "}
                  {"<header>, <footer>, <section>, and <article>"} to improve
                  the structure and readability of your code.
                </li>
                <li>
                  {" "}
                  <span className="fw-semibold">Indent Your Code :</span> Indent
                  nested elements to make the code more readable.
                </li>
                <li>
                  <span className="fw-semibold">Validate Your HTML :</span> Use
                  online tools like the [W3C Markup Validation
                  Service](https://validator.w3.org/) to check for errors in
                  your HTML.
                </li>
                <li>
                  <span className="fw-semibold">
                    Learn CSS and JavaScript :
                  </span>{" "}
                  HTML is just the beginning. To create more dynamic and styled
                  webpages, you’ll need to learn CSS (Cascading Style Sheets)
                  and JavaScript.
                </li>
              </ol>
            </div>

            <div class="meta-bottom">
              <i class="bi bi-folder"></i>
              <ul class="cats">
                <li>
                  <a href="#">Business</a>
                </li>
              </ul>

              <i class="bi bi-tags"></i>
              <ul class="tags">
                <li>
                  <a href="#">Creative</a>
                </li>
                <li>
                  <a href="#">Tips</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>

      <section id="blog-author" class="blog-author section">
        <div class="container">
          <div class="author-container d-flex align-items-center">
            <img
              src="assets/img/blog/blog-author.jpg"
              class="rounded-circle flex-shrink-0"
              alt=""
            />
            <div>
              <h4>Jane Smith</h4>
              <div class="social-links">
                <a href="https://x.com/#">
                  <i class="bi bi-twitter-x"></i>
                </a>
                <a href="https://facebook.com/#">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://instagram.com/#">
                  <i class="biu bi-instagram"></i>
                </a>
              </div>
              <p>
                Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum
                sed possimus accusantium. Quas repellat voluptatem officia
                numquam sint aspernatur voluptas. Esse et accusantium ut unde
                voluptas.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="blog-comments" class="blog-comments section">
        <div class="container">
          <h4 class="comments-count">8 Comments</h4>

          <div id="comment-1" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-1.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Georgia Reader</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Et rerum totam nisi. Molestiae vel quam dolorum vel voluptatem
                  et et. Est ad aut sapiente quis molestiae est qui cum soluta.
                  Vero aut rerum vel. Rerum quos laboriosam placeat ex qui. Sint
                  qui facilis et.
                </p>
              </div>
            </div>
          </div>

          <div id="comment-2" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-2.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Aron Alvarado</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Ipsam tempora sequi voluptatem quis sapiente non. Autem itaque
                  eveniet saepe. Officiis illo ut beatae.
                </p>
              </div>
            </div>

            <div id="comment-reply-1" class="comment comment-reply">
              <div class="d-flex">
                <div class="comment-img">
                  <img src="assets/img/blog/comments-3.jpg" alt="" />
                </div>
                <div>
                  <h5>
                    <a href="">Lynda Small</a>{" "}
                    <a href="#" class="reply">
                      <i class="bi bi-reply-fill"></i> Reply
                    </a>
                  </h5>
                  <time datetime="2020-01-01">01 Jan,2022</time>
                  <p>
                    Enim ipsa eum fugiat fuga repellat. Commodi quo quo dicta.
                    Est ullam aspernatur ut vitae quia mollitia id non. Qui ad
                    quas nostrum rerum sed necessitatibus aut est. Eum officiis
                    sed repellat maxime vero nisi natus. Amet nesciunt nesciunt
                    qui illum omnis est et dolor recusandae. Recusandae sit ad
                    aut impedit et. Ipsa labore dolor impedit et natus in porro
                    aut. Magnam qui cum. Illo similique occaecati nihil modi
                    eligendi. Pariatur distinctio labore omnis incidunt et
                    illum. Expedita et dignissimos distinctio laborum minima
                    fugiat. Libero corporis qui. Nam illo odio beatae enim
                    ducimus. Harum reiciendis error dolorum non autem quisquam
                    vero rerum neque.
                  </p>
                </div>
              </div>

              <div id="comment-reply-2" class="comment comment-reply">
                <div class="d-flex">
                  <div class="comment-img">
                    <img src="assets/img/blog/comments-4.jpg" alt="" />
                  </div>
                  <div>
                    <h5>
                      <a href="">Sianna Ramsay</a>{" "}
                      <a href="#" class="reply">
                        <i class="bi bi-reply-fill"></i> Reply
                      </a>
                    </h5>
                    <time datetime="2020-01-01">01 Jan,2022</time>
                    <p>
                      Et dignissimos impedit nulla et quo distinctio ex nemo.
                      Omnis quia dolores cupiditate et. Ut unde qui eligendi
                      sapiente omnis ullam. Placeat porro est commodi est
                      officiis voluptas repellat quisquam possimus. Perferendis
                      id consectetur necessitatibus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="comment-3" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-5.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Nolan Davidson</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Distinctio nesciunt rerum reprehenderit sed. Iste omnis eius
                  repellendus quia nihil ut accusantium tempore. Nesciunt
                  expedita id dolor exercitationem aspernatur aut quam ut.
                  Voluptatem est accusamus iste at. Non aut et et esse qui sit
                  modi neque. Exercitationem et eos aspernatur. Ea est
                  consequuntur officia beatae ea aut eos soluta. Non qui dolorum
                  voluptatibus et optio veniam. Quam officia sit nostrum
                  dolorem.
                </p>
              </div>
            </div>
          </div>

          <div id="comment-4" class="comment">
            <div class="d-flex">
              <div class="comment-img">
                <img src="assets/img/blog/comments-6.jpg" alt="" />
              </div>
              <div>
                <h5>
                  <a href="">Kay Duggan</a>{" "}
                  <a href="#" class="reply">
                    <i class="bi bi-reply-fill"></i> Reply
                  </a>
                </h5>
                <time datetime="2020-01-01">01 Jan,2022</time>
                <p>
                  Dolorem atque aut. Omnis doloremque blanditiis quia eum porro
                  quis ut velit tempore. Cumque sed quia ut maxime. Est ad aut
                  cum. Ut exercitationem non in fugiat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="comment-form" class="comment-form section">
        <div class="container">
          <form action="">
            <h4>Post Comment</h4>
            <p>
              Your email address will not be published. Required fields are
              marked *{" "}
            </p>
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  name="name"
                  type="text"
                  class="form-control"
                  placeholder="Your Name*"
                />
              </div>
              <div class="col-md-6 form-group">
                <input
                  name="email"
                  type="text"
                  class="form-control"
                  placeholder="Your Email*"
                />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <input
                  name="website"
                  type="text"
                  class="form-control"
                  placeholder="Your Website"
                />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <textarea
                  name="comment"
                  class="form-control"
                  placeholder="Your Comment*"
                ></textarea>
              </div>
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-primary">
                Post Comment
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default BlogDetail;
